import type { BillingAccountCardData, BillingAccountEligiblePromotion } from "@warrenio/api-spec/spec.oats.gen";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { atom } from "jotai/vanilla";
import { first, isEmpty, partition, sortBy } from "remeda";
import invariant from "tiny-invariant";
import { configAtom } from "../../config.ts";
import type { SiteConfig } from "../../config.types.ts";
import { mapQueryData } from "../../utils/query/mergeQueries.ts";
import type { ResourceType } from "../api/resourceTypes.tsx";
import { eBillingAccountQueryAtom, type BillingAccountWithType } from "./billingAccountQuery.ts";
import type { PaymentMethodId } from "./PaymentMethod.tsx";
import { hasCreditCardProcessor } from "./paymentProcessorConfig.ts";

// XXX: is post-pay always a "card"?
export type PayType = "card" | "invoice" | "prepay";

export type ClosedReason = "insufficient_funds" | "suspended" | "missing_payment_method";

export type AccountCallToAction = "must_top_up" | "add_payment_method" | "contact_support" | null;

export type AccountNextAction = AccountCallToAction | "top_up";

export type BillingCallToAction =
    | { action: "create_account" }
    | { action: "contact_support"; account: EBillingAccount }
    | { action: Exclude<AccountCallToAction, null>; account: EBillingAccount }
    | { action: null };

/**
 * Wraps a billing account with useful business logic methods.
 *
 * Prefer it instead of using {@link BillingAccountWithType} directly.
 */
export class EBillingAccount {
    constructor(
        private readonly _account: BillingAccountWithType,
        private readonly config: SiteConfig,
    ) {
        this.validate();
    }

    // Simple wrapper to easily find references from external code that uses the raw account data
    get account() {
        return this._account;
    }

    //#region Simple properties
    get id() {
        return this._account.id;
    }

    get title() {
        return this._account.title;
    }
    //#endregion

    //#region Account state
    get isDefault() {
        return this._account.is_default;
    }

    get isSuspended() {
        return !this._account.is_active;
    }

    // NB: no `isClosed` method to avoid redundancy with `!isClosed == isOpen` and make it easier to search for references.
    // Use `!isOpen` instead.

    /** Can create resources */
    get isOpen() {
        const { restriction_level } = this._account;
        return restriction_level === "CLEAR" || restriction_level === "LIMITED";
    }

    get isLimited() {
        return this._account.restriction_level === "LIMITED";
    }
    //#endregion

    //#region Payment types
    get payType(): PayType {
        if (this.isInvoice) {
            return "invoice";
        } else if (this.isPostPay) {
            return "card";
        } else {
            return "prepay";
        }
    }

    get isPostPay() {
        return this._account.allow_debt;
    }

    get isPrePay() {
        return !this._account.allow_debt;
    }

    get isInvoice() {
        // XXX: Check `this._account.paying_by_invoice` instead?
        return this._account.primary_card?.type === "paying_by_invoice";
    }

    get closedReason(): ClosedReason | null {
        if (this.isOpen) {
            return null;
        }

        if (this.isSuspended) {
            return "suspended";
        } else if (this.isPostPay) {
            return "missing_payment_method";
        } else {
            // invariant(this.isPrePay)
            return "insufficient_funds";
        }
    }

    /** What the user must do now for this account. */
    get callToAction(): AccountCallToAction {
        // TODO: What is the call to action when the account is limited and post-pay/invoice?
        switch (this.closedReason) {
            case "insufficient_funds":
                return "must_top_up";
            case "missing_payment_method":
                return "add_payment_method";
            case "suspended":
                return "contact_support";
            case null:
                return null;
            default:
                return exhaustiveSwitchCheck(this.closedReason);
        }
    }

    /** What the user must do or can do next for this account. */
    get nextAction(): AccountNextAction {
        const { callToAction } = this;
        if (callToAction != null) {
            return callToAction;
        }
        if (this.canTopUp) {
            return "top_up";
        }
        // IDEA: Enable automated top up?
        return null;
    }
    //#endregion

    //#region Post-payment
    get activeCard() {
        const { primary_card } = this._account;
        // XXX: does this need to check `can_pay`? (which would be the same as primary_card.is_verified)
        if (this.isOpen && this.isPostPay && primary_card) {
            return primary_card;
        }
        return null;
    }

    get hasVerifiedCard() {
        return this._account.can_pay;
    }

    get unpaidAmount(): number | null {
        return this.isPostPay ? this._account.unpaid_amount : null;
    }
    //#endregion

    get ongoingAmount(): number {
        return this._account.running_totals?.ongoing ?? 0;
    }

    /** @returns The (positive) amount of credit remaining, if there is any, `null` otherwise. */
    get remainingCredit(): number | null {
        const {
            running_totals: { ongoing, credit_available },
        } = this._account;
        // Check for `credit_available` to make sure the `ongoing` amount reflects any actual remaining credit (and not *just* the total usage)
        return credit_available > 0 && ongoing > 0 ? ongoing : null;
    }

    //#region Pre-payment
    get canTopUp() {
        return this.isPrePay && !this.isSuspended;
    }

    get canEnableAutomatedTopUp() {
        return (
            // XXX: should this check `isOpen`?
            this.isPrePay &&
            this.config.recurringPaymentsEnabled &&
            hasCreditCardProcessor(this.config.paymentProcessors)
        );
    }
    //#endregion

    get eligiblePromotions(): BillingAccountEligiblePromotion[] {
        // NB: Backend does not seem to check for suspended accounts for this field, so double-check here
        return this.isSuspended ? [] : (this._account.eligible_promotions ?? []);
    }

    get canShareReferral() {
        return !!this.referralShareCode;
    }

    get referralShareCode() {
        const { referral_share_code } = this._account;
        return this.config.referralCodeEnabled && referral_share_code ? referral_share_code : null;
    }

    /** Run consistency self-checks */
    private validate() {
        if (!import.meta.env.DEV) {
            return;
        }

        // NB: If any of these fail:
        // -    Check if it's a problem with these checks, or the actual data.
        // -    Leave a comment explaining the case here.
        // -    Create a story / situation to check that it is properly handled in the UI.

        invariant(!this.isSuspended || !this.isOpen, "Suspended account must be closed");
        invariant(!this.isSuspended || !this._account.can_pay, "Suspended account must not have `can_pay`");

        invariant(!this.isInvoice || this.isPostPay, "Invoice account must be post-pay");
        // NB: This does not check if the two are equal since `paying_by_invoice` can apparently be false even with an
        // `primary_card.type` of "paying_by_invoice".
        invariant(!this._account.paying_by_invoice || this.isInvoice, "Invoice flag mismatch");

        const noPromotions = isEmpty(this._account.eligible_promotions ?? []);
        invariant(noPromotions || !this.isSuspended, "Suspended account should not have promotions");
        invariant(noPromotions || !this.isInvoice, "Invoice account should not have promotions");
    }
}

export function getDefaultAccount(items: readonly EBillingAccount[]): EBillingAccount | undefined {
    return items.find((s) => s.isDefault) ?? items[0];
}

export type AccountCreateDisabledReason = "suspended" | null;

export class EBillingState {
    public readonly defaultAccount: EBillingAccount | undefined;

    constructor(
        public readonly accounts: readonly EBillingAccount[],
        private readonly config: SiteConfig,
    ) {
        accounts = sortBy(
            accounts,
            (a) => (a.isOpen ? 1 : 2),
            (a) => a.title,
        );
        const [defaults, nonDefaults] = partition(accounts, (a) => a.isDefault);
        // QUESTION: Should we fall back to the first non-default account if there is no default?
        this.defaultAccount = first(defaults) ?? first(nonDefaults);
        // Always place default first, so it is would be picked first in `.find()` etc. below
        this.accounts = [...defaults, ...nonDefaults];
    }

    get defaultIndex() {
        return this.accounts.length > 0 ? 0 : undefined;
    }

    get suspendedAccount() {
        return this.accounts.find((a) => a.isSuspended);
    }

    get createDisabledReason(): AccountCreateDisabledReason {
        return this.suspendedAccount ? "suspended" : null;
    }

    get firstAccountCredit() {
        return this.accounts.length === 0 ? this.config.firstAccountCredit : null;
    }

    get canEnterReferralCode() {
        // NB: Decision from WRN-223 to hide referral code entry for non-first accounts to prevent "infinite referral
        // stacking" for post-pay accounts, even though the backend does not enforce this.
        return this.accounts.length === 0;
    }

    get callToAction(): BillingCallToAction {
        if (this.accounts.length === 0) {
            return { action: "create_account" };
        }

        // XXX: This does not probably handle deleted *and* suspended accounts correctly
        if (this.suspendedAccount) {
            return { action: "contact_support", account: this.suspendedAccount };
        }

        const hasOpenAccount = this.accounts.some((a) => a.isOpen);
        if (hasOpenAccount) {
            return { action: null };
        }

        const hasVerifiedCard = this.accounts.some((a) => a.hasVerifiedCard);
        if (hasVerifiedCard) {
            return { action: null };
        }

        // QUESTION: Should we even suggest actions for non-default accounts?
        const account = this.accounts.find((a) => a.callToAction != null);
        if (account) {
            return { action: account.callToAction!, account };
        }

        return { action: null };
    }

    canCreateResource(resourceType: ResourceType) {
        switch (resourceType) {
            case "billing_account":
            case "api_token":
            case "access_delegation":
            case "access_impersonation":
                return true;

            case "virtual_machine":
            case "load_balancer":
            case "bucket":
            case "managed_service":
            case "vpc":
            case "ip_address":
                return this.accounts.some((a) => a.isOpen);

            default:
                exhaustiveSwitchCheck(resourceType);
        }
    }
}

export const billingStateAtom = atom((get) =>
    mapQueryData(get(eBillingAccountQueryAtom), (a) => new EBillingState([...a.values()], get(configAtom))),
);

//#region Common card functions

export function getBrandText({ brand, card_type }: BillingAccountCardData) {
    return card_type || brand;
}

export function getPaymentMethodBrandText({ card_type, type }: BillingAccountCardData) {
    if (type === "paying_by_invoice") {
        return "Paying By Invoice";
    }
    return card_type || type;
}

/* Enumerate all the classes here (as literals) so UnoCSS can properly generate them */
const cardTypeIconClasses: Record<string, string> = {
    _default: "jp-creditcards-icon mask-icon",
    "by invoice": "jp-icon-text-editor mask-icon",

    alipay: "jp-alipay-icon bg-icon",
    amex: "jp-amex-icon bg-icon",
    diners: "jp-diners-icon bg-icon",
    discover: "jp-discover-icon bg-icon",
    elo: "jp-elo-icon bg-icon",
    hipercard: "jp-hipercard-icon bg-icon",
    jcb: "jp-jcb-icon bg-icon",
    maestro: "jp-maestro-icon bg-icon",
    mastercard: "jp-mastercard-icon bg-icon",
    paypal: "jp-paypal-icon bg-icon",
    unionpay: "jp-unionpay-icon bg-icon",
    visa: "jp-visa-icon bg-icon",
};

const methodTypeIconClasses: Partial<Record<PaymentMethodId | "_default", string>> = {
    _default: "jp-creditcards-icon",

    invoice: "jp-icon-text-editor",

    stripe_creditcard: "jp-creditcards-icon",
    omise_creditcard: "jp-creditcards-icon",

    "duitku::A1": "jp-duitku-a1-icon",
    "duitku::M1": "jp-duitku-m1-icon",
    "duitku::BK": "jp-duitku-bk-icon",
    "duitku::BC": "jp-duitku-bc-icon",
    "duitku::I1": "jp-duitku-i1-icon",
    "duitku::B1": "jp-duitku-b1-icon",
    "duitku::VC": "jp-duitku-vc-icon",
    "duitku::VA": "jp-duitku-va-icon",
    "duitku::OV": "jp-duitku-ov-icon",
    "duitku::BT": "jp-duitku-bt-icon",
    "duitku::FT": "jp-duitku-ft-icon",
    "duitku::SP": "jp-duitku-sp-icon",
    "duitku::DA": "jp-duitku-da-icon",
    "duitku::AG": "jp-duitku-ag-icon",
    "duitku::S1": "jp-duitku-s1-icon",
    "duitku::BR": "jp-duitku-br-icon",
    "duitku::NC": "jp-duitku-nc-icon",

    "adyen::yandex": "jp-adyen-yandex-icon",
    "stripe_wallet::grabpay": "jp-grabpay-icon",
    "stripe_wallet::wechat_pay": "jp-wechatpay-icon",
    "stripe_wallet::alipay": "jp-omise-alipay-icon",

    "clictopay::clictopay": "jp-clictopay-icon",

    "omise::rabbit_linepay": "jp-omise-linepay-icon",
    "omise::internet_banking_bbl": "jp-omise-bangok-bank-icon",
    "omise::alipay": "jp-omise-alipay-icon",
    "omise::truemoney": "jp-omise-truemoney-icon",
    "omise::internet_banking_bay": "jp-omise-ayudhya-icon",
    "omise::promptpay": "jp-omise-promptpay-icon",

    "sslcommerz::sslcommerz": "jp-sslcommerz-sslcommerz-icon",
    "sslcommerz::othercard": "jp-sslcommerz-othercard-icon",
    "sslcommerz::internetbank": "jp-sslcommerz-internetbank-icon",
    "sslcommerz::mobilebank": "jp-sslcommerz-mobilebank-icon",
    "sslcommerz::brac_visa": "jp-sslcommerz-bracvisa-icon",
    "sslcommerz::dbbl_visa": "jp-sslcommerz-dbblvisa-icon",
    "sslcommerz::city_visa": "jp-sslcommerz-cityvisa-icon",
    "sslcommerz::ebl_visa": "jp-sslcommerz-eblvisa-icon",
    "sslcommerz::sbl_visa": "jp-sslcommerz-sblvisa-icon",
    "sslcommerz::visacard": "jp-sslcommerz-visacard-icon",
    "sslcommerz::brac_master": "jp-sslcommerz-bracmaster-icon",
    "sslcommerz::dbbl_master": "jp-sslcommerz-dbblmaster-icon",
    "sslcommerz::city_master": "jp-sslcommerz-citymaster-icon",
    "sslcommerz::ebl_master": "jp-sslcommerz-eblmaster-icon",
    "sslcommerz::sbl_master": "jp-sslcommerz-sblmaster-icon",
    "sslcommerz::mastercard": "jp-sslcommerz-mastercard-icon",
    "sslcommerz::city_amex": "jp-sslcommerz-cityamex-icon",
    "sslcommerz::amexcard": "jp-sslcommerz-amexcard-icon",
    "sslcommerz::qcash": "jp-sslcommerz-qcash-icon",
    "sslcommerz::dbbl_nexus": "jp-sslcommerz-dbblnexus-icon",
    "sslcommerz::bankasia": "jp-sslcommerz-bankasia-icon",
    "sslcommerz::abbank": "jp-sslcommerz-abbank-icon",
    "sslcommerz::ibbl": "jp-sslcommerz-ibbl-icon",
    "sslcommerz::mtbl": "jp-sslcommerz-mtbl-icon",
    "sslcommerz::bkash": "jp-sslcommerz-bkash-icon",
    "sslcommerz::dbblmobilebanking": "jp-sslcommerz-dbblmobilebanking-icon",
    "sslcommerz::city": "jp-sslcommerz-city-icon",
    "sslcommerz::upay": "jp-sslcommerz-upay-icon",
    "sslcommerz::tapnpay": "jp-sslcommerz-tapnpay-icon",
};

export const defaultCardIconClass = "jp-card-icon mask-icon text-primary";

export function getCardIconClass(card: BillingAccountCardData | null | undefined) {
    if (card) {
        const key = card.card_type || card.brand;
        const icon = cardTypeIconClasses[key ? key.toLowerCase() : "_default"];
        return `${icon} text-primary`;
    }

    return defaultCardIconClass;
}

export function getMethodLinkIconClass(key: PaymentMethodId | null | undefined, isSelected = false) {
    if (key) {
        const icon = methodTypeIconClasses[key ?? "_default"];
        return isSelected ? `${icon} mask-icon text-primary` : `${icon} bg-icon text-primary`;
    }

    return defaultCardIconClass;
}

//#endregion
